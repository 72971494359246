<template>
    <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px" :rules="rules">
      <div class="col col4">
        <el-form-item label="供应商编码"  prop="supplierCode">
            <el-input  v-model="form.supplierCode"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称"  prop="supplierName">
            <el-input v-model="form.supplierName"></el-input>
        </el-form-item>
        <el-form-item label="审核年度"  prop="auditYear">
            <el-input  v-model="form.auditYear "  ></el-input>
        </el-form-item>
        <el-form-item label="审核说明"  prop="auditDesc">
            <el-input  v-model="form.auditDesc"  ></el-input>
        </el-form-item>
      </div>
    </el-form>
    </div>
</template>

<script>
export default {
  name: 'CreatedDialog',
  data: function () {
    return {
      rules: {
        supplierCode: [{ required: true, message: '请输入供应商编码', trigger: 'blur' }],
        supplierName: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        auditYear: [{ required: true, message: '请输入审核年度', trigger: 'blur' }],
        auditDesc: [{ required: true, message: '请输入审核说明', trigger: 'blur' }]
      },
      form: {}
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
.form{
    :deep(.el-input__inner){
        width: 50%;
    }
}
</style>
